import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import configData from '../config.json';

import Head from "../components/Head";
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';


function ImagePage() {
  const { t } = useTranslation();
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [baseImage, setBaseImage] = useState('');
  const [createdImage, setCreatedImage] = useState('');
  const [sessionName, setSessionName] = useState('');
  const [prompt, setPrompt] = useState('');

  const [imageSenssionId, setImageSessionId] = useState('');
  const [sessionList, setSessionList] = useState([]);
  const [selectedNavItem, setSelectedNavItem] = useState('0');

  const userId = localStorage.getItem('userid');

  const apiGetSessionHistory = (userId) => {
    fetch(configData.API_URL + `/study/office/images/`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        setSessionList(data.images);
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    apiGetSessionHistory(userId);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  function apiModifySessionName(photoSenssionId) {
    const requestData = {
      sessionId: photoSenssionId,
      name: sessionName,
    };

    fetch(configData.API_URL + `/study/office/changeImageName/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          alert('Modify image name success');
          apiGetSessionHistory(userId);
        } else {
          alert('Modify image name failed!');
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  function onModifyNameClick() {
    apiModifySessionName(imageSenssionId);
  }

  function onNewSession() {
    setImageSessionId('');
    setBaseImage('');
    setCreatedImage('');
    setPrompt('');
    setSessionName('');
    setSelectedNavItem('0');
  }

  function onSessionSelect(eventKey) {
    if (windowWidth < 576 && isSessionOpen) {
      setIsSessionOpen(false);
    }
    const session = sessionList.find(item => item._id == eventKey);
    // console.log(session);
    setImageSessionId(session._id);
    // setBaseImage(session.src_url);
    setCreatedImage(session.dstUrl);
    setPrompt(session.request.content);
    setSessionName(session.name);
    setSelectedNavItem(eventKey);
  }

  const startCreateImage = () => {
    disableInput(true);
    // let image_file = null;
    // if (fileInputRef.current && fileInputRef.current.files.length > 0) {
    //   image_file = fileInputRef.current.files[0];
    // }
    // if (image_file !== null) {
    //   const fileExtension = image_file.name.split(".").pop();
    //   const currentTime = new Date();
    //   const year = currentTime.getFullYear();
    //   const month = String(currentTime.getMonth() + 1).padStart(2, '0');
    //   const day = String(currentTime.getDate()).padStart(2, '0');
    //   const hours = String(currentTime.getHours()).padStart(2, '0');
    //   const minutes = String(currentTime.getMinutes()).padStart(2, '0');
    //   const seconds = String(currentTime.getSeconds()).padStart(2, '0');
    //   let filename = `my_image_${year}${month}${day}_${hours}${minutes}${seconds}.${fileExtension}`;
    //   formData.append("image", image_file, filename);
    // }

    const requestData = {
      prompt: prompt,
      sessionName: sessionName,
      sessionId: '',
    };

    // send formData to server
    fetch(configData.API_URL + '/study/office/createImage/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        disableInput(false);
        // console.log(data);
        if (data.success === false) {
          alert(data.msg);
        } else {
          setImageSessionId(data.reply.sessionId);
          // setBaseImage(data.reply.src_url);
          setSessionName(data.reply.name);
          setCreatedImage(data.reply.dstUrl);
          apiGetSessionHistory(userId);
        }
      })
      .catch(error => {
        disableInput(false);
        console.log(error);
      });
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    setBaseImage(URL.createObjectURL(e.target.files[0]));
  };

  const onImageClick = () => {
    fileInputRef.current.click();
  }

  const onPromptChange = (event) => {
    setPrompt(event.target.value);
  }

  const onNameChange = (event) => {
    setSessionName(event.target.value);
  }

  function disableInput(disabled) {
    // const btnUpload = document.getElementById('btn-upload');
    // btnUpload.disabled = disabled;
    const btnStave = document.getElementById('btn-save');
    btnStave.disabled = disabled;
    const btnStart = document.getElementById('btn-generate');
    btnStart.disabled = disabled;
    const loading = document.getElementById('loading-div');
    if (disabled) {
      loading.style.display = 'block';
    } else {
      loading.style.display = 'none';
    }
  }

  const toggleSessionList = () => setIsSessionOpen(!isSessionOpen);

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center screen-height'>

          <div className='chatgpt-title'>
            {windowWidth < 576 && (
              <Button variant='outline-success' onClick={toggleSessionList}>
                {isSessionOpen ? '<' : '>'}
              </Button>
            )}
            <div className='chatgpt-title-text'>
              <h5> {t('label_title_image_creator')} </h5>
            </div>
            <Button variant="success" onClick={onNewSession}> New </Button>
          </div>

          <div className='sub-right-container'>

            <Collapse in={isSessionOpen}>
              <div className='sub-left d-sm-block'>
                <Nav className='flex-column' onSelect={onSessionSelect} activeKey={selectedNavItem}>
                  {sessionList.map((session, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={session['_id']}
                        style={{ color: 'grey', fontWeight: '500' }}
                      >
                        {session['name']}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            </Collapse>

            <div className='sub-right flex-grow-1'>
              <div className='photo-detail-container'>

                {/* <div className="photo-detail-row">
                  <div className='photo-desc-container'>
                    <input type='file' 
                          ref={fileInputRef} 
                          style={{ display: "none" }} 
                          onChange={handleImageChange} />
                    <Button variant="outline-success" size='lg' id='btn-upload' onClick={onImageClick}>
                      {t('btn_upload_image')}
                    </Button>
                    <p className='break-line'>
                      {t('tip1_upload_image')}
                      <br />
                      {t('tip2_upload_image')}
                    </p>
                  </div>
                  {baseImage != '' && (<div className='photo-img-container'>
                    <img src={baseImage} className="photo-detail-img" alt="原始照片" />
                  </div>)}
                </div> */}

                <div className="photo-detail-row">
                  <InputGroup>
                    <Form.Control as="textarea" placeholder={t('tip_input_prompt')} rows={"3"}
                      value={prompt} onChange={onPromptChange} />
                  </InputGroup>
                </div>

                <div className="photo-detail-row">
                  <div className='photo-desc-container'>
                    <Button variant="outline-success" size='lg' id='btn-generate' onClick={startCreateImage}>
                      {t('btn_generate_image')}
                    </Button>
                    <div id='loading-div' style={{ display: 'none' }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </div>
                  </div>
                  {createdImage != '' && (<div className='photo-img-container'>
                    <img src={createdImage} className="photo-detail-img" alt="Created Image" />
                  </div>)}
                </div>

                <div className="photo-detail-row">
                  <form>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1"> Name: </InputGroup.Text>
                      <Form.Control
                        placeholder=""
                        aria-label="SessionName"
                        aria-describedby="basic-addon1"
                        type="text"
                        value={sessionName}
                        onChange={onNameChange}
                      />
                      <Button variant="outline-success" size='lg' id="btn-save" onClick={onModifyNameClick}> Save </Button>
                    </InputGroup>
                  </form>
                </div>

                <div className="photo-detail-row">
                  <p></p>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <Tail />
    </div>
  );
}

export default ImagePage;
