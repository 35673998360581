import { useEffect, useState } from 'react';
import configData from '../config.json';

import Head from "../components/Head";
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Spinner from 'react-bootstrap/Spinner';


function OfficePage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLeftMenuOpen, setLeftMenuOpen] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState('ai-email');
  const [show, setShow] = useState(false);

  const [popMessage, setPopMessage] = useState('');
  const [originalMail, setOriginalMail] = useState('');
  const [require, setRequire] = useState('');
  const [generatedMail, setGeneratedMail] = useState('');

  const userId = localStorage.getItem('userid');

  function apiSendMessage(remark) {
    let message = '';
    if (selectedNavItem === 'ai-email') {
      message = '请回复下面这封邮件';
      if (require !== '') {
        message = ', 要求回复包含：' + require;
      }
      message = message + ': \n' + originalMail;
      // console.log(message);
    } else if (selectedNavItem === 'english-polish') {
      message = 'Please polish the article below: \n' + originalMail;
      // console.log(message);
    } else if (selectedNavItem === 'ai-translate') {
      if (remark === 'Han2English') {
        // Han2English
        message = '请翻译下面的内容到英语: \n' + originalMail;
      } else {
        // English2Han
        message = 'Please translate the following content into Chinese: \n' + originalMail;
      }
      // console.log(message);
    }
    if (message === '') {
      return;
    }

    const requestData = {
      message: message,
      history: JSON.stringify([]),
    };

    fetch(configData.API_URL + '/study/office/chatgpt/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.success) {
          setGeneratedMail(data.data.content);
        } else {
          setPopMessage('Error, ' + data.message);
        }
        disableInput(false);
      })
      .catch(error => {
        console.log(error);
        disableInput(false);
      });
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (popMessage.length > 0) {
      setShow(true);
    }
  }, [popMessage]);

  function disableInput(disabled) {
    const input = document.getElementById('original-textarea');
    input.disabled = disabled;
    const button = document.getElementById('button-generate');
    button.disabled = disabled;
    const spinner = document.getElementById('spinner-loading');
    spinner.hidden = !disabled;
    const button2 = document.getElementById('button-generate2');
    if (button2 !== null) {
      button2.disabled = disabled;
    }
  }

  const onGenerateClick = () => {
    disableInput(true);
    apiSendMessage('');
  }

  const onHan2EnglishClick = () => {
    disableInput(true);
    apiSendMessage('Han2English');
  }

  const onEnglish2HanClick = () => {
    disableInput(true);
    apiSendMessage('English2Han');
  }

  const onClearClick = () => {
    setOriginalMail('');
  }

  const onPasteClick = () => {
    if (navigator.clipboard === undefined) {
      setPopMessage('剪贴板操作异常');
      return;
    }
    navigator.clipboard.writeText(generatedMail)
      .then(() => {
        // console.log('Text copied to clipboard');
        setPopMessage('已复制到剪贴板');
      })
      .catch((error) => {
        setPopMessage('错误: ', error);
      });
  }

  const onOriginalMailChange = (e) => {
    setOriginalMail(e.target.value);
  }

  const onRequireChange = (e) => {
    setRequire(e.target.value);
  }

  const onGeneratedMailChange = (e) => {
    setGeneratedMail(e.target.value);
  }

  function onLeftMenuSelect(eventKey) {
    if (windowWidth < 576 && isLeftMenuOpen) {
      setLeftMenuOpen(false);
    }
    if (eventKey !== selectedNavItem) {
      setOriginalMail('');
      setGeneratedMail('');
      setSelectedNavItem(eventKey);
    }
    // console.log(eventKey, 'selected');
  }

  const toggleLeftMenuList = () => {
    setLeftMenuOpen(!isLeftMenuOpen);
  }

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center' style={{maxWidth: '1000px'}}>
          
          <div className='sub-right-container'>
            {/* 可折叠菜单 */}
            <Collapse in={isLeftMenuOpen}>
            <div className='sub-left d-sm-block'>
              <Nav className='flex-column' onSelect={onLeftMenuSelect} activeKey={selectedNavItem}>
                <Nav.Item key={'index-1'}>
                  <Nav.Link eventKey={'ai-email'} style={{ color: 'grey', fontWeight: '500' }}> &#9830; 智能邮件 </Nav.Link>
                </Nav.Item>
                <Nav.Item key={'index-2'}>
                  <Nav.Link eventKey={'english-polish'} style={{ color: 'grey', fontWeight: '500' }}> &#9830; 英文修饰 </Nav.Link>
                </Nav.Item>
                <Nav.Item key={'index-3'}>
                  <Nav.Link eventKey={'ai-translate'} style={{ color: 'grey', fontWeight: '500' }}> &#9830; AI翻译 </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            </Collapse>

            <div className='sub-right flex-grow-1'>
              {windowWidth < 576 && (
                <Button variant='outline-success' onClick={toggleLeftMenuList} style={{marginTop: '5px'}}>
                  {isLeftMenuOpen ? '<' : '菜单'}
                </Button>
              )}

              {selectedNavItem === 'ai-email' && (
                <div>
                  <div className="configure-block-title">
                    <h5>原邮件</h5>
                    <Button variant="outline-success" onClick={onClearClick}> 清空 </Button>
                  </div>
                  <div className="configure-block-content">
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder="复制粘贴你的邮件..."
                        aria-label="Write your message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="original-textarea"
                        rows={10}
                        value={originalMail}
                        onChange={onOriginalMailChange}
                      />
                    </InputGroup>
                  </div>
                  <div className="configure-block-title">
                    <h5>回复邮件</h5>
                    <Spinner animation="border" variant="success" id='spinner-loading' hidden />
                  </div>
                  <div className="configure-block-content">
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder="输入你的要求(如：礼貌的回复)，或直接点击生成..."
                        aria-label="Write your message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="require-textarea"
                        value={require}
                        onChange={onRequireChange}
                      />
                      <Button variant="outline-success" id="button-generate" onClick={onGenerateClick}> 生成邮件 </Button>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        aria-label="Generated message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="generated-textarea"
                        rows={10}
                        value={generatedMail}
                        onChange={onGeneratedMailChange}
                      />
                    </InputGroup>
                    <Button variant="outline-success" onClick={onPasteClick}> 复制到剪贴板 </Button>
                  </div>
                </div>)}
              
              {selectedNavItem === 'english-polish' && (
                <div>
                  <div className="configure-block-title">
                    <h5>英文文章</h5>
                    <Button variant="outline-success" onClick={onClearClick}> 清空 </Button>
                  </div>
                  <div className="configure-block-content">
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder="复制粘贴你的英文文章..."
                        aria-label="Write your message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="original-textarea"
                        rows={10}
                        value={originalMail}
                        onChange={onOriginalMailChange}
                      />
                    </InputGroup>
                  </div>
                  <div className="configure-block-title">
                    <h5>生成的内容</h5>
                    <Spinner animation="border" variant="success" id='spinner-loading' hidden />
                    <Button variant="outline-success" id="button-generate" onClick={onGenerateClick}> 开始处理 </Button>
                  </div>
                  <div className="configure-block-content">
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        aria-label="Generated message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="generated-textarea"
                        rows={10}
                        value={generatedMail}
                        onChange={onGeneratedMailChange}
                      />
                    </InputGroup>
                    <Button variant="outline-success" onClick={onPasteClick}> 复制到剪贴板 </Button>
                  </div>
                </div>)}
              
              {selectedNavItem === 'ai-translate' && (
                <div>
                  <div className="configure-block-title">
                    <h5>翻译内容</h5>
                    <Button variant="outline-success" onClick={onClearClick}> 清空 </Button>
                  </div>
                  <div className="configure-block-content">
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder="粘贴或输入需要翻译的内容..."
                        aria-label="Write your message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="original-textarea"
                        rows={10}
                        value={originalMail}
                        onChange={onOriginalMailChange}
                      />
                    </InputGroup>
                  </div>
                  <div className="configure-block-title">
                    <h5>翻译结果</h5>
                    <Spinner animation="border" variant="success" id='spinner-loading' hidden />
                  </div>
                  <div className="configure-block-content">
                    <div>
                      <Button variant="outline-success" id="button-generate" onClick={onHan2EnglishClick}> 汉译英 </Button>{' '}
                      <Button variant="outline-success" id="button-generate2" onClick={onEnglish2HanClick}> 英译汉 </Button>
                    </div>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        aria-label="Generated message here..."
                        aria-describedby="basic-addon2"
                        className="textarea-input"
                        id="generated-textarea"
                        rows={10}
                        value={generatedMail}
                        onChange={onGeneratedMailChange}
                      />
                    </InputGroup>
                    <Button variant="outline-success" onClick={onPasteClick}> 复制到剪贴板 </Button>
                  </div>
                </div>)}
            </div>
          </div>

        </div>
      </div>

      <ToastContainer
          className="p-3"
          position={'middle-center'}
          style={{ zIndex: 1 }}
        >
        <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">消息</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body> {popMessage} </Toast.Body>
        </Toast>
      </ToastContainer>
      <Tail />
    </div>
  );
}

export default OfficePage;
