import { useNavigate } from 'react-router-dom';

import Head from '../components/Head';
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function HomePage() {
  const navigate = useNavigate();

  function onChatClick(event) {
    event.preventDefault();
    navigate('/chatgpt');
  }

  function onPhotoClick(event) {
    event.preventDefault();
    window.open("https://www.idphotodiy.net/", '_blank');
  }

  function onImageClick(event) {
    event.preventDefault();
    navigate('/image_creator');
  }

  function onOfficeClick(event) {
    event.preventDefault();
    navigate('/office');
  }

  return (
    <div>
      <Head />

      <div className='main-container'>
        <div className='container-center' style={{maxWidth: '900px'}}>

          <Card style={{ width: '330px', height: '490px', margin: '50px', float: 'left' }}>
            <Card.Img variant="top" src="images/baike.png" />
            <Card.Body>
              <Card.Title>随意问</Card.Title>
              <Card.Text>
              &#9674; 随时随地解答您的疑问，拓宽知识视野，是您掌上的个人智能助手。
              <br />
              &#9674; 构建属于您的个人知识库，记录您的心路历程。
              <br />
              &#9674; 无论何时何地，只需轻轻一点，便能解答您的疑惑，拓宽您的知识视野，是您掌上的个人智囊团。
              </Card.Text>
              <Button variant="primary" onClick={onChatClick}>去试试</Button>
            </Card.Body>
          </Card>

          <Card style={{ width: '330px', height: '490px', margin: '50px', float: 'left' }}>
            <Card.Img variant="top" src="images/id-photo.png" />
            <Card.Body>
              <Card.Title>证件照</Card.Title>
              <Card.Text>
              &#9674; 自己动手制作标准的护照、签证等多种证件照片。
              <br />
              &#9674; 采用先进的人工智能技术，自动检测面部信息，并对照片的背景、尺寸、人物比例以及光线亮度进行优化调整。
              <br />
              &#9674; 重视用户隐私，所有上传的照片都会被加密存储，并在处理完成后立即删除。
              </Card.Text>
              <Button variant="primary" onClick={onPhotoClick}>去试试</Button>
            </Card.Body>
          </Card>

          <Card style={{ width: '330px', height: '420px', margin: '50px', float: 'left' }}>
            <Card.Img variant="top" src="images/imagine.png" />
            <Card.Body>
              <Card.Title>AI图像生成</Card.Title>
              <Card.Text>
              &#9674; 描述您的想象，AI会自动为您生成符合您想法的图片。
              <br />
              &#9674; 该功能仍处于开发初期，仅供娱乐。
              <br />
              </Card.Text>
              <Button variant="primary" onClick={onImageClick}>去试试</Button>
            </Card.Body>
          </Card>

          <Card style={{ width: '330px', height: '420px', margin: '50px', float: 'left' }}>
            <Card.Img variant="top" src="images/translation.png" />
            <Card.Body>
              <Card.Title>智能办公</Card.Title>
              <Card.Text>
              &#9674; 邮件回复 - 帮您自动生成回复邮件内容，更人性化，节省时间。
              <br />
              &#9674; 英文修饰 - 帮您生成更加地道的英文。
              <br />
              &#9674; 翻译 - 比传统翻译更口语化，人性化。
              <br />
              </Card.Text>
              <Button variant="primary" onClick={onOfficeClick}>去试试</Button>
            </Card.Body>
          </Card>

        </div>
      </div>
      <Tail />
    </div>
  );
}

export default HomePage;
