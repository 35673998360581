import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import ConfigurationPage from './pages/Configuration';
import HomePage from './pages/Home';
import ImagePage from './pages/Image';
import ChatGPTPage from './pages/ChatGPT';
import CustomerServicePage from './pages/CustomerService';
import OfficePage from './pages/Office';
import TranslaterPage from './pages/Translater';
import PaymentPage from './pages/Payment';
import AdminPage from './pages/Admin';


const initialOptions = {
  "client-id": "ARFMkR5YiA6nrd8aKBQDRObBSex2teHN7tRrd087SbV0QTINFZLEBJ-aommppPmLOrAr6c0PVaGvF6V-",
  "currency": "CAD",
  // "enable-funding": "paylater,venmo",
};

function App() {
  return (
    <PayPalScriptProvider options={initialOptions}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ChatGPTPage />} />
        <Route path='/chatgpt' element={<ChatGPTPage />} />
        <Route path="/ai_cs" element={<CustomerServicePage />} />
        <Route path='/office' element={<OfficePage />} />
        <Route path='/image_creator' element={<ImagePage />} />
        <Route path='/translater' element={<TranslaterPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/account' element={<ConfigurationPage />} />
        <Route path='/payment' element={<PaymentPage />} />
        <Route path='/admin' element={<AdminPage />} />
      </Routes>
    </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
