import { useEffect, useState, useRef } from 'react';
import configData from '../config.json';
import moment from 'moment';

import Head from "../components/Head";
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import ImageCropper from '../components/ImageCropper';


function ChatGPTPage() {
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const divRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);

  const [chatSenssionId, setChatSessionId] = useState('');
  const [history, setHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedNavItem, setSelectedNavItem] = useState('0');
  const [sessionList, setSessionList] = useState([]);
  const [todaySessionList, setTodaySessionList] = useState([]);
  const [lastWeekSessionList, setLastWeekSessionList] = useState([]);
  const [earlierSessionList, setEarlierSessionList] = useState([]);

  const [showCropModal, setShowCropModal] = useState(false);

  const userId = localStorage.getItem('userid');

  const apiGetSessionHistory = (userId) => {
    fetch(configData.API_URL + `/study/studio/topics/`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          setSessionList(data.topics);
        } else {
          console.log(data);
        }
      })
      .catch(error => console.log(error));
  }

  const apiGetChatHistoryBySessionId = (id) => {
    fetch(configData.API_URL + `/study/studio/messages/?topicId=${id}`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          const newHistory = [];
          data.messages.forEach(item => {
            if (Array.isArray(item.request)) {
              item.request.forEach(r => {
                r.content.forEach(msg => {
                  if (msg.type === "image_url") {
                    const req = {
                      msgId: item._id,
                      role: r.role,
                      type: 'image',
                      content: '',
                      image: msg.image_url.url,
                      duration: 0
                    };
                    newHistory.push(req);
                  } else {
                    const req = {
                      msgId: item._id,
                      role: r.role,
                      type: msg.type,
                      content: msg.text,
                      image: null,
                      duration: 0
                    };
                    newHistory.push(req);
                  }
                });
              });
            } else {
              const req = {
                msgId: item._id,
                role: item.request.role,
                type: 'text',
                content: item.request.content,
                image: null,
                duration: 0
              };
              newHistory.push(req);
            }
            const res = {
              msgId: item._id,
              role: item.response ? item.response.role : '',
              content: item.response ? item.response.content : '',
              duration: item.duration
            };
            newHistory.push(res);
          });
          setHistory(newHistory);
          setChatSessionId(id);
          setMessage('');
        } else {
          console.log(data);
        }
      })
      .catch(error => console.log(error));
  }

  const apiDeleteSession = (id) => {
    fetch(configData.API_URL + `/study/studio/topic/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'manual',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        alert(data.message);
        if (data.success) {
          apiGetSessionHistory(userId);
          onNewSession();
        } else {
          console.log(data.msg);
        }
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    apiGetSessionHistory(userId);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [history]);

  useEffect(() => {
    let todaySessions = [];
    let lastWeekSessions = [];
    let earlierSessions = [];

    const now = moment(); // 当前时间

    sessionList.forEach(session => {
      let sessionDate = moment(session.createDate);

      if (sessionDate.isSame(now, 'day')) {
        todaySessions.push(session);
      } else if (sessionDate.isBetween(now.clone().subtract(7, 'days'), now)) {
        lastWeekSessions.push(session);
      } else {
        earlierSessions.push(session);
      }
    });

    setTodaySessionList(todaySessions);
    setLastWeekSessionList(lastWeekSessions);
    setEarlierSessionList(earlierSessions);
  }, [sessionList]);

  function createChatTask() {
    if (imageFile != null && imageFile !== '') {
      startImageChatTask();
    } else {
      startTextChatTask();
    }
  }

  function startTextChatTask() {
    const requestData = {
      topicId: chatSenssionId,
      topicType: 'TextChat',
      question: message,
    };

    fetch(configData.API_URL + '/study/studio/chat', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          setChatSessionId(data.data.topicId);
          startQuestionSteam(data.data.topicId, data.data.chatId);
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  function startQuestionSteam(topicId, msgId) {
    let response = '';
    const apiUrl = configData.API_URL + '/study/studio/response/' + topicId;
    var source = new EventSource(apiUrl);

    source.addEventListener('message', function (event) {
      response += event.data;
      // console.log('message', event);
      const newHistory = [...history,
      { msgId: msgId, role: 'user', type: 'text', content: message, image: '', duration: 0 },
      { msgId: msgId, role: 'assistant', type: 'text', content: response, image: '', duration: 0 }
      ];
      setHistory(newHistory);
    });

    source.addEventListener('end', function (event) {
      // console.log('End: ', event);
      source.close();
      setMessage('');
      const newHistory = [...history,
      { msgId: msgId, role: 'user', type: 'text', content: message, image: '', duration: 0 },
      { msgId: msgId, role: 'assistant', type: 'text', content: response, image: '', duration: event.data }
      ];
      setHistory(newHistory);
      disableInput(false);
    });

    source.addEventListener('error', function (event) {
      console.log('An error occurred:', event.data);
      source.close();
      setMessage('');
      const newHistory = [...history,
      { msgId: msgId, role: 'user', type: 'text', content: message, image: '', duration: 0 },
      { msgId: msgId, role: 'assistant', type: 'text', content: event.data, image: '', duration: 0 }
      ];
      setHistory(newHistory);
      disableInput(false);
    });
  }

  function disableInput(disabled) {
    const input = document.getElementById('chatgpt-textarea');
    input.disabled = disabled;
    const button = document.getElementById('button-send');
    button.disabled = disabled;
  }

  function onSendClick() {
    disableInput(true);
    const newHistory = [...history,
    { msgId: '', role: 'user', type: 'text', content: message, image: '', duration: 0 },
    { msgId: '', role: 'assistant', type: 'text', content: '', image: '', duration: 0 }
    ];
    setHistory(newHistory);
    createChatTask();
  }

  function onNewSession() {
    setChatSessionId('');
    setHistory([]);
    setMessage('');
    setImageFile('');
    setSelectedNavItem('');
    apiGetSessionHistory(userId);
  }

  function onDeleteSession() {
    apiDeleteSession(chatSenssionId);
  }

  function onSessionSelect(eventKey) {
    if (windowWidth < 576 && isSessionOpen) {
      setIsSessionOpen(false);
    }
    setSelectedNavItem(eventKey);
    apiGetChatHistoryBySessionId(eventKey);
  }

  function autoResize() {
    var textArea = document.getElementById("chatgpt-textarea");
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSendClick();
    }
  }

  const scrollToBottom = () => {
    const div = divRef.current;
    div.scrollTop = div.scrollHeight;
  }

  const toggleSessionList = () => setIsSessionOpen(!isSessionOpen);

  const startImageChatTask = () => {
    // console.log(imageFile);
    const fileExtension = 'png';
    if (imageFile.hasOwnProperty('name')) {
      fileExtension = imageFile.name.split(".").pop();
    }
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, '0');
    const day = String(currentTime.getDate()).padStart(2, '0');
    const hours = String(currentTime.getHours()).padStart(2, '0');
    const minutes = String(currentTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentTime.getSeconds()).padStart(2, '0');
    let filename = `image_${userId}_${year}${month}${day}_${hours}${minutes}${seconds}.${fileExtension}`;

    const formData = new FormData();
    if (imageFile.hasOwnProperty('name')) {
      formData.append("file", imageFile, filename);
    } else {
      formData.append("file", imageBlob, filename);
    }
    formData.append("topicType", "ImageChat");
    formData.append("name", filename);
    formData.append("question", message);

    // send formData to server
    fetch(configData.API_URL + '/study/studio/createImageChat', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
      },
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          setChatSessionId(data.data.topicId);
          startQuestionSteam(data.data.topicId, data.data.chatId);
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  };

  const handleImageChange = (e) => {
    // console.log(e.target.files[0]);
    setImageFile(e.target.files[0]);
    const newHistory = [...history,
    { role: 'user', type: 'image', content: '', image: URL.createObjectURL(e.target.files[0]), duration: 0 },
    { role: 'assistant', type: 'text', content: 'Please enter your question about this image', image: '', duration: 0 }
    ];
    setHistory(newHistory);
  };

  const onAddonImageClick = () => {
    setShowCropModal(true);
  }

  const onCropModalClose = () => {
    setShowCropModal(false);
  }

  const onCropComplete = (croppedImageBlob) => {
    setImageBlob(croppedImageBlob);
    const imageUrl = URL.createObjectURL(croppedImageBlob); // URL.createObjectURL(croppedImageBlob);
    setImageFile(imageUrl);
    const newHistory = [...history,
    { role: 'user', type: 'image', content: '', image: imageUrl, duration: 0 },
    { role: 'assistant', type: 'text', content: 'Please enter your question about this image', image: '', duration: 0 }
    ];
    setHistory(newHistory);
    setShowCropModal(false);
  }

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center screen-height'>

          <div className='chatgpt-title'>
            {windowWidth < 576 && (
              <Button variant='outline-success' onClick={toggleSessionList}>
                {isSessionOpen ? '<' : '>'}
              </Button>
            )}
            <div className='chatgpt-title-text'>
              <h5>ChatGPT</h5>
            </div>
            <div>
              {chatSenssionId !== '' && (
                <Button variant="success" onClick={onDeleteSession}> Delete </Button>
              )} {' '}
              <Button variant="success" onClick={onNewSession}> New Topic </Button>
            </div>
          </div>

          <div className='sub-right-container'>

            <Collapse in={isSessionOpen}>
              <div className='sub-left d-sm-block'>
                <Nav className='flex-column' onSelect={onSessionSelect} activeKey={selectedNavItem}>
                  <p className='session-group-title'> Today </p>
                  {todaySessionList.map((session, index) => (
                    <Nav.Item key={index} style={{ width: "100%" }}>
                      <Nav.Link eventKey={session['_id']} style={{ color: 'grey', fontWeight: '500', width: "100%" }}>
                        {session['name'].substring(0, 30)}
                      </Nav.Link>
                    </Nav.Item>
                  ))}

                  <p className='session-group-title'> Previous 7 days </p>
                  {lastWeekSessionList.map((session, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={session['_id']} style={{ color: 'grey', fontWeight: '500' }}>
                        {session['name'].substring(0, 30)}
                      </Nav.Link>
                    </Nav.Item>
                  ))}

                  <p className='session-group-title'> Earlier </p>
                  {earlierSessionList.map((session, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={session['_id']} style={{ color: 'grey', fontWeight: '500' }}>
                        {session['name'].substring(0, 30)}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            </Collapse>

            <div className='sub-right flex-grow-1'>
              <div ref={divRef} className='chat-box-container'>
                {history.map((msg, index) => {
                  if (msg['role'] === 'user') {
                    return (
                      <div className='chat-box chat-box-me' key={index}>
                        <div className='chat-box-image-text'>
                          <p>Me</p>
                        </div>
                        {msg['type'] === 'image' && (
                          <div className='chat-box-text'>
                            <img src={msg['image']} className='chat-box-sent-image'></img>
                          </div>
                        )}
                        {msg['type'] === 'text' && (
                          <div className='chat-box-text'>
                            <p className='break-line'>{msg['content']}</p>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <div className='chat-box chat-box-ai' key={index}>
                        <div>
                          <img className='chat-box-image' src='/images/head-logo.png' alt='' />
                        </div>
                        <div className='chat-box-text'>
                          <p className='break-line'>{msg['content']}</p>
                          {/* <p className='chat-box-text-time'>
                            {msg['duration'] == 0 ? 'Generating...' : msg['duration'] + ' secs'}
                          </p> */}
                        </div>
                      </div>
                    );
                  }
                })
                }
              </div>

              <div className='chat-input-container flex-grow-1'>
                <ImageCropper modalShow={showCropModal} onClose={onCropModalClose} onCropComplete={onCropComplete} />
                <InputGroup className="mb-3">
                  <DropdownButton id="button-addon" title="+" variant="outline-success" drop='up-centered' disabled={chatSenssionId !== ''}>
                    <Dropdown.Item as="button" onClick={onAddonImageClick}>Add image</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter your question..."
                    aria-label="Write your message here..."
                    aria-describedby="basic-addon2"
                    className="textarea-input"
                    id="chatgpt-textarea"
                    value={message}
                    onInput={autoResize}
                    onChange={onMessageChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Button variant="outline-success" id="button-send" onClick={onSendClick}> Send </Button>
                </InputGroup>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Tail />
    </div>
  );
}

export default ChatGPTPage;
