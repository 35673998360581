import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import configData from '../config.json';

import Head from "../components/Head";
import Table from 'react-bootstrap/Table';


function AdminPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registeredUsers, setRegisteredUsers] = useState(0);
  const [userList, setUserList] = useState([]);

  const userId = localStorage.getItem('userid');

  const apiGetAdminData = () => {
    fetch(configData.API_URL + `/user/dashboard/`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          setRegisteredUsers(data.registeredUsers);
          setUserList(data.userList);
        }
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    apiGetAdminData();
  }, []);

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center' style={{ maxWidth: '850px' }}>

          <div className="configure-block-title">
            <h4>{'Registered User: ' + registeredUsers}</h4>
          </div>
          <div className="configure-block-content">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Source</th>
                  <th>Joined Time</th>
                  <th>Last Time</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.username}</td>
                    <td>{user.source}</td>
                    <td>{user.joinDate}</td>
                    <td>{user.lastLogin}</td>
                    <td>{user.isActive ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

        </div>
      </div>

    </div>
  );
}

export default AdminPage;
