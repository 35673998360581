import { useEffect, useState } from 'react';
import configData from '../config.json';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function RegisterPage() {
  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const handleUsernameChange = (event) => {
    let name = event.target.value;
    name = name.trim();
    setUsername(name);
  }

  const handlePassword1Change = (event) => {
    let password = event.target.value;
    setPassword1(password.trim());
  }

  const handlePassword2Change = (event) => {
    let password = event.target.value;
    setPassword2(password.trim());
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password1 !== password2) {
      alert('The passwords entered twice do not match!');
      return;
    }

    const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    let name = username.trim();
    if (!re.test(name)) {
      alert('Please enter a valid email address.');
      return;
    }

    const requestData = {
      username: name,
      password: password1.trim(),
      source: 'Studio',
    };

    fetch(configData.API_URL + '/user/register/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          alert(data.message);
          // jump to main page
          window.location.replace('/login');
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div className='background'>
      <div className='bg-line'></div>

      <div className='login-container'>
        <img src={'/images/logo4.png'} className="login-logo" alt="logo" />

        <div className="login">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='login-label'>Email:</Form.Label>
              <Form.Control type="text" placeholder="Enter your email" value={username} onChange={handleUsernameChange} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className='login-label'>Password: </Form.Label>
              <Form.Control type="password" placeholder="Enter password" id='input-password1' value={password1} onChange={handlePassword1Change} />
              <p></p>
              <Form.Control type="password" placeholder="Password again" id='input-password2' value={password2} onChange={handlePassword2Change} />
            </Form.Group>

            <Button variant="success" type="submit" > Register </Button>
          </Form>
        </div>

        <div className='login-version'>{process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}

export default RegisterPage;
