import { useEffect, useState } from 'react';
import configData from '../config.json';

import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    let username = event.target.value;
    setUsername(username.trim());
  }

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    setPassword(password.trim());
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestData = {
      username: username.trim(),
      password: password.trim(),
    };

    fetch(configData.API_URL + '/user/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          const nickname = data.user.firstName ? data.user.firstName : data.user.username;
          localStorage.setItem('username', data.user.username);
          localStorage.setItem('nickname', nickname);
          localStorage.setItem('email', data.user.email);
          localStorage.setItem('userid', data.user.token);
          if (data.user.userType === 'Admin') {
            localStorage.setItem('isSuperuser', 'true');
          } else {
            localStorage.setItem('isSuperuser', 'false');
          }
          // jump to main page
          window.location.replace('/');
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div className='background'>
      <div className='bg-line'></div>

      <div className='login-container'>
        <img src={'/images/logo4.png'} className="login-logo" alt="logo" />

        <div className="login">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='login-label'>Email:</Form.Label>
              <Form.Control type="text" placeholder="Enter your email" value={username} onChange={handleUsernameChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className='login-label'>Password: </Form.Label>
              <Form.Control type="password" placeholder="Enter password" value={password} onChange={handlePasswordChange} />
            </Form.Group>

            <Button variant="success" type="submit" > Login </Button>
          </Form>
          <Nav.Link href="/register" className='login-reg'>I don't have an account yet</Nav.Link>
        </div>

        <div className='login-version'>{process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}

export default LoginPage;
