
function Tail() {
    return (
      <footer>
        <div className='tail-container'>
          <div className='container-center top-border'>
            <p>欢迎使用山竹科技 v{process.env.REACT_APP_VERSION}</p>
            <p>联系我们: <a href="mailto:service@mangosteen.one">service@mangosteen.one</a></p>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Tail;
  