import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import configData from '../config.json';

import Head from "../components/Head";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Tail from '../components/Tail';


function TranslaterPage() {
  const { t } = useTranslation();
  const [csrfToken, setCsrfToken] = useState('');

  const [source, setSource] = useState('');
  const [target, setTarget] = useState('');

  const apiGetCsrfToken = () => {
    fetch(configData.API_URL + '/user/csrfToken')
    .then(response => response.json())
    .then(data => {
      document.cookie = `csrftoken=${data['X-CSRFToken']}; path=/`;
      setCsrfToken(data['X-CSRFToken']);
    })
    .catch(error => console.log(error));
  }

  const apiTranslate = (toEnglish) => {
    const data = new FormData();
    data.append('source', source);
    if (toEnglish) {
      data.append('target', 'en');
    } else {
      data.append('target', 'zh');
    }

    fetch(configData.API_URL + `/ai/translate/`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'manual',
      headers: {
        'X-CSRFToken': csrfToken
      },
      body: data
    })
    .then(response => response.json())
    .then(data => {
      disableInput(false);
      // console.log(data);
      if (data.success) {
        setTarget(data.reply.reply.content);
      } else {
        alert(data.msg);
      }
    })
    .catch(error => {
      disableInput(false);
      console.log(error);
    });
  }

  useEffect(() => {
    apiGetCsrfToken();
  }, []);

  function disableInput(disabled) {
    const input = document.getElementById('btn-han-eng');
    input.disabled = disabled;
    const button = document.getElementById('btn-eng-han');
    button.disabled = disabled;
  }

  const onToEnglishClick = () => {
    disableInput(true);
    apiTranslate(true);
  }

  const onToChineseClick = () => {
    disableInput(true);
    apiTranslate(false);
  }

  const onSourceChange = (e) => {
    setSource(e.target.value);
  }

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center' style={{maxWidth: '850px'}}>

        <div className="configure-block-title">
            <h4> {t('label_ai_translater')} </h4>
          </div>
          <div className="configure-block-content">
            <InputGroup>
              <Form.Control as="textarea" aria-label="With textarea" value={source} onChange={onSourceChange} />
            </InputGroup>
            <div className='row-content'>
              <Button variant="success" id="btn-han-eng" onClick={onToEnglishClick}> 汉 &gt; English </Button>
              <Button variant="success" id="btn-eng-han" onClick={onToChineseClick}> English &gt; 汉 </Button>
            </div>
            <div className='row-content'>
              <p className='break-line'> {target} </p>
            </div>
          </div>

        </div>
      </div>
      <Tail />
    </div>
  );
}

export default TranslaterPage;
